@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
   
    .header-bg-clip {
        clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
    }

    @media screen(md) {
        .header-bg-clip {
            clip-path: none;
        }
    }
    

    

    @font-face {
        font-family: Flama;
        src:  url('./fonts/Flama Semicondensed Bold Regular.ttf') format('truetype');
    }

    @font-face {
        font-family: NimbusSans;
        src:  url('./fonts/NimbusSanL-Reg.otf') format('truetype');
    }

    @font-face {
        font-family: NimbusSans;
        font-weight: bold;
        src:  url('./fonts/NimbusSanL-Bol.otf') format('opentype');
    }

    @font-face {
        font-family: 'RO Sans';
        font-weight: bold;
        src:  url('./fonts/RO-SansWebText-Bold.woff') format('woff');
    }

    @font-face {
        font-family: 'RO Sans';
        font-weight: italic;
        src:  url('./fonts/RO-SansWebText-Italic.woff') format('woff');
    }

    @font-face {
        font-family: 'RO Sans';
        src:  url('./fonts/RO-SansWebText-Regular.woff') format('woff');
    }

}